


























import { defineComponent, PropType } from '@vue/composition-api'

import FlexibleVideo from '@/components/flexible/Video.vue'

interface FlexibleDuo {
  title: string
  intro: string
  video: {
    loop: {
      url: string
    }
    youtubeId: string
  }
}

export default defineComponent({
  name: 'FlexibleDuo',
  components: {
    FlexibleVideo,
  },
  props: {
    content: {
      type: Object as PropType<FlexibleDuo>,
      required: true,
    },
  },
})
